<template>
  <div v-if="isLoaded">
    <!--begin::Notice-->
    <b-jumbotron
      v-if="!company.has_paid_subscription"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Sie müssen ein kostenpflichtes Abonement abschliessen um ihr
        Firmenportrait zu erfassen. Das Erfassen eines Firmenportrait ist nicht
        im Grundpreis des Jahresabonement enthalten, kann jedoch als Option
        hinzufügen.
      </template>
      <router-link
        to="/adjust-subscription"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Abonement abschliessen
        </b-button>
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Notice-->
    <b-jumbotron
      v-else-if="!company.has_portrait"
      bg-variant="info"
      text-variant="white"
      border-variant="light"
    >
      <template v-slot:lead>
        Die Option um ein Firmenprofil zu erfassen ist in Ihrem Abonment nicht
        enthalten.
      </template>
      <hr class="my-4" />
      <p>
        Sie können jedoch weitere Optionen jederzeit zu Ihrem Abonoment
        hinzufügen.
      </p>
      <router-link
        to="/add-subscription-option"
        v-slot="{ href, navigate, isActive, isExactActive }"
      >
        <b-button variant="success" @click="navigate"
          >Optionen hinzufügen
        </b-button>
      </router-link>
    </b-jumbotron>
    <!--end::Notice-->
    <!--begin::Card-->
    <div class="card card-custom" v-else>
      <!-- begin::Form-->
      <b-form class="form" v-on:submit.prevent="save">
        <!--begin::Body-->
        <div class="card-body py-8 px-8 py-lg-20 px-lg-10">
          <div class="row">
            <div class="offset-xxl-2 col-xxl-8">
              <h4 class="mb-10 font-weight-bold text-dark">
                Firmenportrait
              </h4>
              <div class="row">
                <div class="col-xl-12">
                  <!--                  <ValidatedTextArea-->
                  <!--                    v-model="companyPortrait.portrait"-->
                  <!--                    :v="$v.companyPortrait.portrait"-->
                  <!--                    errorMessage="Das ist ein Pflichtfeld."-->
                  <!--                  />-->
                  <RichTextArea v-model="companyPortrait.portrait" />
                </div>
              </div>
              <h4 class="mb-10 mt-10 font-weight-bold text-dark">
                Bilder
              </h4>
              <div class="row pb-10">
                <div
                  class="col-xl-4 pb-6"
                  v-for="(image,
                  index) in companyPortrait.company_portrait_images"
                  :key="index"
                >
                  <div class="image-input image-input-outline">
                    <b-img
                      thumbnail
                      fluid
                      :src="portraitImage(image)"
                      alt="Company Logo"
                    ></b-img>
                    <span
                      class="btn btn-xs btn-icon btn-circle btn-white btn-hover-text-primary btn-shadow"
                      data-action="remove"
                      data-toggle="tooltip"
                      title="Remove avatar"
                      @click="image.image = null"
                    >
                      <i class="ki ki-bold-close icon-xs text-muted"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="row pb-10">
                <div class="col-xl-6">
                  <b-form-file
                    placeholder="Bild auswählen..."
                    drop-placeholder="Drop file here..."
                    accept="image/*"
                    multiple
                    @change="onFileChange($event)"
                  ></b-form-file>
                </div>
                <div class="col-xl-6">
                  <!--begin: Actions -->
                  <div class="d-flex justify-content-between">
                    <div class="mr-2"></div>
                    <div>
                      <button
                        class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
                        data-wizard-type="action-submit"
                      >
                        Speichern
                      </button>
                    </div>
                  </div>
                  <!--end: Actions -->
                </div>
              </div>

              <!--begin: Actions -->
              <!--              <div class="d-flex justify-content-between border-top pt-10">-->
              <!--                <div class="mr-2"></div>-->
              <!--                <div>-->
              <!--                  <button-->
              <!--                    class="btn btn-success font-weight-bold text-uppercase px-9 py-4"-->
              <!--                    data-wizard-type="action-submit"-->
              <!--                  >-->
              <!--                    Speichern-->
              <!--                  </button>-->
              <!--                </div>-->
              <!--              </div>-->
              <!--end: Actions -->

              <div class="border-bottom mt-10 mb-10" />

              <CompanyReferenceProjects
                :companyPortrait="companyPortrait"
                :companyReferenceProjects="companyReferenceProjects"
                @reloadProjects="loadData"
              />
            </div>
          </div>
        </div>
        <!--end::Body-->
      </b-form>
      <!--end::Form-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import RichTextArea from "@/components/RichTextArea";
import CompanyReferenceProjects from "@/view/pages/ReferenceProjects/CompanyReferenceProjects";
import { alertMixin } from "@/mixins/alertMixin";
import { LOAD_USER_COMPANY } from "@/store/company.module";
import {
  LOAD_USER_COMPANY_PORTRAIT,
  UPDATE_USER_COMPANY_PORTRAIT
} from "@/store/portrait.module";
import { LOAD_USER_COMPANY_REFERENCE_PROJECTS_LIST } from "@/store/referenceprojects.module";

export default {
  components: { RichTextArea, CompanyReferenceProjects },
  mixins: [validationMixin, alertMixin],
  name: "CompanyPortrait",
  data() {
    return {
      companyPortrait: {},
      companyReferenceProjects: {},
      company: {},
      default_image: "media/no_image.jpg",
      isLoaded: false
    };
  },
  validations: {
    companyPortrait: {
      portrait: {
        required
      }
    }
  },
  mounted() {
    this.loadData();
  },
  methods: {
    validateState(name) {
      const { $dirty, $error } = this.$v.company[name];
      return $dirty ? !$error : null;
    },
    async loadData() {
      // Load first the languages before the company is loaded
      await Promise.all([
        this.$store.dispatch(LOAD_USER_COMPANY),
        this.$store.dispatch(LOAD_USER_COMPANY_PORTRAIT),
        this.$store.dispatch(LOAD_USER_COMPANY_REFERENCE_PROJECTS_LIST)
      ])
        .then(() => {
          this.company = this.getUserCompany;
          this.companyPortrait = this.getUserCompanyPortrait;
          this.companyReferenceProjects = this.getUserCompanyReferenceProjectsList;
        })
        .catch(() => {
          // User has no portrait
        });
      this.isLoaded = true;
    },
    save() {
      this.$v.companyPortrait.$touch();
      if (this.$v.companyPortrait.$anyError) {
        this.showCheckProvidedDataAlert();
        return;
      }

      this.$store
        .dispatch(UPDATE_USER_COMPANY_PORTRAIT, this.companyPortrait)
        .then(() => {
          this.companyPortrait = this.getUserCompanyPortrait;
          this.showChangesSavedAlert();
        });
    },
    onFileChange(e) {
      if (e.target.files.length > this.getRemainingNrOfImages()) {
        this.showToMuchFilesAlert(
          this.companyPortrait.max_nr_of_images,
          this.getRemainingNrOfImages()
        );
        return;
      }

      e.target.files.forEach(file => {
        if (typeof FileReader === "function") {
          const reader = new FileReader();

          reader.onload = event => {
            // this.company.logo = event.target.result;
            this.applyImage(event.target.result);
          };

          reader.readAsDataURL(file);
        } else {
          alert("Sorry, FileReader API not supported");
        }
      });
    },
    portraitImage(image) {
      if (image != null) {
        return image.image != null ? image.image : this.default_image;
      }
      return this.default_image;
    },
    getNrOfImages() {
      let imgCount = 0;
      if (this.companyPortrait.company_portrait_images != null) {
        this.companyPortrait.company_portrait_images.forEach(companyImage => {
          imgCount += companyImage.image != null ? 1 : 0;
        });
      }
      return imgCount;
    },
    getRemainingNrOfImages() {
      if (this.companyPortrait.max_nr_of_images != null) {
        return this.companyPortrait.max_nr_of_images - this.getNrOfImages();
      }
      return 0;
    },
    applyImage(image) {
      this.companyPortrait.company_portrait_images.some(companyImage => {
        if (companyImage.image == null) {
          companyImage.image = image;
          return true;
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      "getUserCompanyPortrait",
      "getUserCompanyReferenceProjectsList",
      "getUserCompany"
    ])
  }
};
</script>
