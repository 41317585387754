<template>
  <div>
    <h4 class="mb-10 font-weight-bold text-dark">
      Referenzprojekte ({{
        companyReferenceProjects.length +
          "/" +
          companyPortrait.max_nr_of_reference_projects
      }})
    </h4>

    <!--begin::Table-->
    <div class="table-responsive">
      <table
        class="table table-head-custom table-vertical-center table-head-bg table-borderless"
      >
        <thead>
          <tr class="text-left">
            <th style="min-width: 150px" class="pl-7">
              <span class="text-dark-75">Projektbezeichnung</span>
            </th>
            <th style="min-width: 120px" class="text-right">Status</th>
            <th style="min-width: 200px" class="text-right"></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(item, i) in companyReferenceProjects">
            <tr v-bind:key="i">
              <td class="py-5">
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg ml-4"
                  >{{ item.description }}</span
                >
              </td>
              <td class="text-right">
                <span
                  class="label label-lg label-inline"
                  v-bind:class="'label-light-primary'"
                >
                  {{ item.status_display }}
                </span>
              </td>
              <td class="pr-0 text-right">
                <router-link
                  :to="{
                    name: 'company-reference',
                    params: { id: item.id }
                  }"
                  v-slot="{ href, navigate, isActive, isExactActive }"
                >
                  <a
                    @click="navigate"
                    class="btn btn-light-success btn-sm font-weight-bolder font-size-sm mr-4"
                    >Bearbeiten</a
                  >
                </router-link>
                <a
                  @click="deleteReferenceProject(item.id)"
                  class="btn btn-light-danger btn-sm font-weight-bolder font-size-sm mr-4"
                  >Löschen</a
                >
              </td>
            </tr>
          </template>
        </tbody>
      </table>
    </div>
    <!--end::Table-->

    <!--begin: Actions -->
    <div class="d-flex justify-content-between border-top pt-10">
      <div class="mr-2"></div>
      <div>
        <router-link
          to="/company-reference"
          v-slot="{ href, navigate, isActive, isExactActive }"
        >
          <button
            @click="navigate"
            class="btn btn-success font-weight-bold text-uppercase px-9 py-4"
            :disabled="
              companyReferenceProjects.length >=
                companyPortrait.max_nr_of_reference_projects
            "
          >
            <i class="flaticon2-add"></i>Projekt hinzufügen
          </button>
        </router-link>
      </div>
    </div>
    <!--end: Actions -->
  </div>
</template>

<script>
import Swal from "sweetalert2";
import { alertMixin } from "@/mixins/alertMixin";
import { DELETE_USER_COMPANY_REFERENCE_PROJECT } from "@/store/referenceprojects.module";

export default {
  mixins: [alertMixin],
  name: "CompanyReferenceProjects",
  props: {
    companyPortrait: {
      required: true
    },
    companyReferenceProjects: {
      required: true
    }
  },
  methods: {
    deleteReferenceProject(id) {
      Swal.fire({
        title: "",
        text: "Sind Sie sicher das sie das Projekt löschen wollen?",
        icon: "info",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger"
        },
        confirmButtonText: "Löschen!",
        cancelButtonText: "Abbrechen",
        reverseButtons: true
      }).then(result => {
        if (result.isConfirmed) {
          this.$store
            .dispatch(DELETE_USER_COMPANY_REFERENCE_PROJECT, id)
            .then(() => {
              this.showSuccessAlert(
                "",
                "Das Referenzprojekt wurde erfolgreich gelöscht."
              );
              // Reload data
              this.$emit("reloadProjects");
            });
        }
      });
    }
  }
};
</script>

<style scoped></style>
