<template>
  <div>
    <editor-menu-bar :editor="editor" v-slot="{ commands, isActive }">
      <div
        class="d-flex align-items-center p-2 bg-light"
        style="border-radius: 0.3rem;"
      >
        <a
          class="btn btn-transparent-dark btn-sm mr-1"
          :class="{ active: isActive.bold() }"
          @click="commands.bold"
        >
          <i class=" fa fa-bold"></i>
        </a>
        <a
          class="btn btn-transparent-dark btn-sm mr-1"
          :class="{ active: isActive.italic() }"
          @click="commands.italic"
          ><i class="fa fa-italic"></i
        ></a>
        <a
          class="btn btn-transparent-dark btn-sm mr-1"
          :class="{ active: isActive.underline() }"
          @click="commands.underline"
          ><i class="menu-icon fa fa-underline"></i
        ></a>
      </div>
    </editor-menu-bar>
    <editor-content class="editor-box pt-2" :editor="editor" v-model="input" />
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import { Bold, Italic, Underline } from "tiptap-extensions";

export default {
  components: { EditorContent, EditorMenuBar },
  name: "RichTextArea",
  props: {
    value: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editor: null
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      }
    }
  },
  mounted() {
    this.editor = new Editor({
      extensions: [new Bold(), new Italic(), new Underline()],
      content: this.value,
      onUpdate: ({ getHTML }) => {
        this.input = getHTML();
      }
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  }
};
</script>

<style>
.editor-box > * {
  margin-top: 2px;
  border-style: none;
  background-color: #f3f6f9;
  padding: 0.5rem 1rem;
  font-size: 1.08rem;
  border-radius: 0.3rem;
  min-height: 20rem;
}

.is-active {
  border-color: grey;
  border-style: solid;
  border-width: 1px;
}
/* *:focus {
   outline: none;
}  */
</style>
