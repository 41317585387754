var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h4',{staticClass:"mb-10 font-weight-bold text-dark"},[_vm._v(" Referenzprojekte ("+_vm._s(_vm.companyReferenceProjects.length + "/" + _vm.companyPortrait.max_nr_of_reference_projects)+") ")]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-head-custom table-vertical-center table-head-bg table-borderless"},[_vm._m(0),_c('tbody',[_vm._l((_vm.companyReferenceProjects),function(item,i){return [_c('tr',{key:i},[_c('td',{staticClass:"py-5"},[_c('span',{staticClass:"text-dark-75 font-weight-bolder d-block font-size-lg ml-4"},[_vm._v(_vm._s(item.description))])]),_c('td',{staticClass:"text-right"},[_c('span',{staticClass:"label label-lg label-inline",class:'label-light-primary'},[_vm._v(" "+_vm._s(item.status_display)+" ")])]),_c('td',{staticClass:"pr-0 text-right"},[_c('router-link',{attrs:{"to":{
                  name: 'company-reference',
                  params: { id: item.id }
                }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('a',{staticClass:"btn btn-light-success btn-sm font-weight-bolder font-size-sm mr-4",on:{"click":navigate}},[_vm._v("Bearbeiten")])]}}],null,true)}),_c('a',{staticClass:"btn btn-light-danger btn-sm font-weight-bolder font-size-sm mr-4",on:{"click":function($event){return _vm.deleteReferenceProject(item.id)}}},[_vm._v("Löschen")])],1)])]})],2)])]),_c('div',{staticClass:"d-flex justify-content-between border-top pt-10"},[_c('div',{staticClass:"mr-2"}),_c('div',[_c('router-link',{attrs:{"to":"/company-reference"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var href = ref.href;
                var navigate = ref.navigate;
                var isActive = ref.isActive;
                var isExactActive = ref.isExactActive;
return [_c('button',{staticClass:"btn btn-success font-weight-bold text-uppercase px-9 py-4",attrs:{"disabled":_vm.companyReferenceProjects.length >=
              _vm.companyPortrait.max_nr_of_reference_projects},on:{"click":navigate}},[_c('i',{staticClass:"flaticon2-add"}),_vm._v("Projekt hinzufügen ")])]}}])})],1)])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',{staticClass:"text-left"},[_c('th',{staticClass:"pl-7",staticStyle:{"min-width":"150px"}},[_c('span',{staticClass:"text-dark-75"},[_vm._v("Projektbezeichnung")])]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"120px"}},[_vm._v("Status")]),_c('th',{staticClass:"text-right",staticStyle:{"min-width":"200px"}})])])}]

export { render, staticRenderFns }